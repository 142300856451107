import PropTypes from 'prop-types';
import React from 'react';

import template from './Popup.jsx';

const ENTER_KEY_CODE = 13;
const ESCAPE_KEY_CODE = 27;

export default class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: false,
    };
    this.render = template.bind(this);
  }

  static get propTypes() {
    return {
      text: PropTypes.string,
    };
  }

  hidePopup() {
    this.setState({ hidden: true });
  }

  onKeyDown(e) {
    if (e.keyCode === ESCAPE_KEY_CODE || e.keyCode === ENTER_KEY_CODE) {
      this.hidePopup();
    }
  }

  onContentClick(e) {
    e.stopPropagation();
  }
}
