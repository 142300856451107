/*eslint no-undef: 0 react/no-render-return-value: 0 */
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';

import commonUtils from '../scripts/commonUtils.js';
import paramUtils from '../scripts/paramUtils.js';
import LoginElement from './components/LoginElement.jsx';
import './login.scss';

window.globals = {
  versions: _.toPlainObject(document.getElementById('login-script').dataset),
  config: commonUtils.getDefaultGlobalConfig(),
};

function renderElement(element) {
  window.rendered = ReactDOM.render(element, document.getElementById('main'));
}

localStorage.removeItem('csrf');

window.addEventListener('load', function () {
  commonUtils.initGlobalConfig(() => {
    let params = paramUtils.parseParams('?', location.search);
    let MainLoginComponent = React.createElement(LoginElement, { params });
    renderElement(MainLoginComponent);
  });
});
