import React from 'react';
import $ from 'zepto';

export default class RequestWithCSRF extends React.Component {
  post(callback) {
    $.ajax({
      type: 'POST',
      url: `${window.globals.versions.api}/auth/new_session/`,
      success: this.handleCSRFToken.bind(this, callback),
      error: function (xhr, errorType) {
        console.error(errorType);
      },
    });
  }

  handleCSRFToken(callback, result) {
    if (result && result.success) {
      callback(result.csrf);
    } else {
      this.setState({ error: 'other', message: null });
    }
  }
}
