import _ from 'lodash';

import consts from './consts.js';

const DEFAULT_PRIVACY_POLICY_LINK = 'https://www.armis.com/privacy-policy';

function getErrorText(error) {
  switch (error) {
    case consts.ERROR_PASSWORD:
      return consts.ERROR_PASSWORD_MESSAGE;
    case consts.ERROR_MISSING_REQUIRED_FIELD:
      return consts.ERROR_MISSING_REQUIRED_FIELD_MESSAGE;
    case consts.ERROR_MISSING_USERNAME:
      return consts.ERROR_MISSING_USERNAME_MESSAGE;
    case consts.ERROR_INVALID_CAPTCHA:
      return consts.ERROR_INVALID_CAPTCHA_MESSAGE;
    case consts.ERROR_PASSWORDS_MISMATCH:
      return consts.ERROR_PASSWORDS_MISMATCH_MESSAGE;
    case consts.ERROR_INVALID_PASSWORD:
      return consts.ERROR_INVALID_PASSWORD_MESSAGE;
    case consts.ERROR_EXPIRED_RESET_TOKEN:
      return consts.ERROR_EXPIRED_RESET_TOKEN_MESSAGE;
    case consts.ERROR_NO_OTP:
      return '';
    case consts.ERROR_INVALID_OTP:
      return consts.ERROR_INVALID_OTP_MESSAGE;
    case consts.ERROR_CONCURRENT_SESSIONS:
      return consts.ERROR_CONCURRENT_SESSIONS_MESSAGE;
    case consts.ERROR_UNCHECKED_EULA_AGREEMENT:
      return consts.ERROR_UNCHECKED_EULA_AGREEMENT_MESSAGE;
    default:
      return error || consts.ERROR_OTHER_MESSAGE;
  }
}

function isEmbeddedRequest(url) {
  return _.includes(decodeURIComponent(url), '/embedded/');
}

function isOtpMode(resultMessage) {
  return (
    resultMessage === consts.ERROR_NO_OTP ||
    resultMessage === consts.ERROR_INVALID_OTP
  );
}

function getLabelClass(value) {
  return value ? 'label' : 'label hidden';
}

function getPrivacyPolicyLink() {
  return _.get(
    window.globals.config,
    ['links', 'privacyPolicy'],
    DEFAULT_PRIVACY_POLICY_LINK
  );
}

export default {
  isEmbeddedRequest,
  isOtpMode,
  getErrorText,
  getLabelClass,
  getPrivacyPolicyLink,
};
