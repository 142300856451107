import React from 'react';

import consts from './consts.js';

export default function template() {
  if (this.state.hidden) {
    return null;
  }
  return (
    <div
      className="popup"
      onClick={this.hidePopup.bind(this)}
      tabIndex="0"
      onKeyDown={this.onKeyDown.bind(this)}
    >
      <div
        className="popup-content"
        onClick={this.onContentClick.bind(this)}
        tabIndex="0"
        onKeyDown={this.onKeyDown.bind(this)}
      >
        <p className="text">{this.props.text}</p>
        <div className="button" onClick={this.hidePopup.bind(this)}>
          {consts.LOGIN_BANNER_BUTTON_TEXT}
        </div>
      </div>
    </div>
  );
}
