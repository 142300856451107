export default {
  ERROR_EXPIRED_RESET_TOKEN: 'expired-reset-token',
  ERROR_EXPIRED_RESET_TOKEN_MESSAGE:
    'The reset link has expired. To receive a new link, return to the Login page and click Forgot password.',
  ERROR_INVALID_CAPTCHA: 'invalid-captcha',
  ERROR_INVALID_CAPTCHA_MESSAGE: 'Invalid Captcha',
  ERROR_INVALID_OTP: 'invalid-otp',
  ERROR_INVALID_OTP_MESSAGE: 'Invalid security token, please try again',
  ERROR_INVALID_PASSWORD: 'invalid-password',
  ERROR_INVALID_PASSWORD_MESSAGE:
    'Please make sure the password has at least 8 characters, and combines lower case, upper case and special characters',
  ERROR_MISSING_REQUIRED_FIELD: 'missing-required-field',
  ERROR_MISSING_REQUIRED_FIELD_MESSAGE: 'Required field is missing',
  ERROR_MISSING_USERNAME: 'missing-username',
  ERROR_MISSING_USERNAME_MESSAGE: 'Please provide a username',
  ERROR_NO_OTP: 'no-otp',
  ERROR_NO_OTP_MESSAGE: '',
  ERROR_OTHER: 'other',
  ERROR_OTHER_MESSAGE: 'Unknown error, please try again',
  ERROR_PASSWORD: 'password',
  ERROR_PASSWORD_MESSAGE: 'Wrong username or password',
  ERROR_PASSWORDS_MISMATCH: 'passwords-mismatch',
  ERROR_PASSWORDS_MISMATCH_MESSAGE: 'Passwords do not match',
  ERROR_SSO_UNAVAILABLE:
    'SSO is unavailable, please contact your administrator for assistance',
  ERROR_UNCHECKED_EULA_AGREEMENT: 'unchecked eula agreement',
  ERROR_UNCHECKED_EULA_AGREEMENT_MESSAGE: 'Please check eula agreement first',

  INPUT_NAME_OTP: 'otpToken',
  INPUT_NAME_PASSWORD: 'password',
  INPUT_NAME_PASSWORD_REPEAT: 'passwordRepeat',
  INPUT_NAME_USERNAME: 'username',

  LOGIN_FORGOT_PASSWORD: 'Forgot Password',
  LOGIN_NEXT: 'Next',
  LOGIN_PASSWORD_PLACEHOLDER: 'Password',
  LOGIN_RESET_PASSWORD_EMAIL_SENT_MESSAGE:
    'Email sent to the registered address',
  LOGIN_SUBMIT: 'Log in',
  LOGIN_TITLE: 'Log in to your Armis account',
  LOGIN_USERNAME_PLACEHOLDER: 'Username or email',

  LOGIN_BANNER_BUTTON_TEXT: 'Continue to Login',

  OTP_PLACEHOLDER: '2-Step Verification code',

  RESET_PASSWORD_PLACEHOLDER: 'New Password',
  RESET_PASSWORD_REPEAT_PLACEHOLDER: 'New Password (again)',
  RESET_PASSWORD_SUBMIT: 'Reset Password',
  RESET_PASSWORD_TITLE: 'Please provide a new password',

  CREATE_PASSWORD_TITLE: 'Please provide a password',
  CREATE_PASSWROD_SUBMIT: 'Done',
};
