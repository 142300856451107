export class MessageBufferService<T> {
  private buffer: Array<T>;

  constructor() {
    this.buffer = [];
  }

  public addMessage(message: T): void {
    this.buffer.push(message);
  }

  public flushBuffer(): Array<T> {
    const messages = [...this.buffer];
    this.buffer = [];
    return messages;
  }

  public getBuffer(): Array<T> {
    return this.buffer;
  }
}
