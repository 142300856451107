import { LoggerConfiguration } from '../contracts';
import { Environment, LogLevel } from '../utils/enums';

export const LOGGER_DEFAULT_CONFIGURATION: LoggerConfiguration = {
  enable: true,
  bufferDelay: 5000,
  telemetryHost: '/telemetry/logs',
  rateLimiter: 100,
  logLevel: LogLevel.error,
  env: Environment.prod,
};
