import _ from 'lodash';
import PropTypes from 'prop-types';
import $ from 'zepto';

import RequestWithCSRF from './RequestWithCSRF.js';
import template from './ResetPassword.jsx';
import common from './common.js';
import consts from './consts.js';

export default class ResetPassword extends RequestWithCSRF {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordRepeat: '',
      error: null,
      otpMode: false,
      otpToken: null,
      isSigningEulaRequired: false,
      isEulaChecked: false,
    };
    this.render = template.bind(this);
  }

  componentDidMount() {
    this.checkSigningEulaRequired();
  }

  static get propTypes() {
    return {
      params: PropTypes.object.isRequired,
    };
  }

  onInputChange(key, e) {
    let obj = {};
    obj[key] = e.target.value;
    obj.error = null;
    this.setState(obj);
  }

  isFieldEmpty() {
    return (
      (this.state.otpMode && _.isEmpty(this.state.otpToken)) ||
      (!this.state.otpMode &&
        (_.isEmpty(this.state.password) ||
          _.isEmpty(this.state.passwordRepeat)))
    );
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.isFieldEmpty()) {
      this.setState({
        error: consts.ERROR_MISSING_REQUIRED_FIELD,
        message: null,
      });
      return;
    }
    if (this.state.isSigningEulaRequired) {
      if (!this.state.isEulaChecked) {
        this.setState({
          error: consts.ERROR_UNCHECKED_EULA_AGREEMENT,
          message: null,
        });
        return;
      }
    }
    if (this.props.params.it) {
      this.createUser();
    } else {
      this.resetPassword();
    }
  }

  createUser() {
    if (this.state.password === this.state.passwordRepeat) {
      this.post((csrf) => {
        $.ajax({
          type: 'POST',
          url: `${window.globals.versions.api}/auth/create_user_from_invitation/`,
          headers: {
            Authorization: `Armis ${csrf}`,
          },
          data: {
            username: this.props.params.u,
            password: this.state.password,
            invitationToken: this.props.params.it,
          },
          success: this.handleResetPasswordResult.bind(this),
          error: function (xhr, errorType) {
            console.error(errorType);
          },
        });
      });
    } else {
      this.setState({ error: consts.ERROR_PASSWORDS_MISMATCH, message: null });
    }
  }

  resetPassword() {
    if (this.state.password === this.state.passwordRepeat) {
      this.post((csrf) => {
        $.ajax({
          type: 'POST',
          url: `${window.globals.versions.api}/auth/reset_password/`,
          headers: {
            Authorization: `Armis ${csrf}`,
          },
          data: {
            invitationToken: this.props.params.it,
            username: this.props.params.u,
            password: this.state.password,
            resetToken: this.props.params.rt,
            reset_token: this.props.params.rt, // eslint-disable-line camelcase
            otpToken: this.state.otpToken,
            otp_token: this.state.otpToken, // eslint-disable-line camelcase
            isSigningEulaRequired: this.state.isSigningEulaRequired,
          },
          success: this.handleResetPasswordResult.bind(this),
          error: function (xhr, errorType) {
            console.error(errorType);
          },
        });
      });
    } else {
      this.setState({ error: consts.ERROR_PASSWORDS_MISMATCH, message: null });
    }
  }

  handleResetPasswordResult(result) {
    if (result.success) {
      location.href = '/login';
    } else {
      this.setState({
        otpMode: common.isOtpMode(result.message),
        error: result.message || consts.ERROR_OTHER_MESSAGE,
        message: null,
      });
    }
  }

  checkSigningEulaRequired() {
    this.post((csrf) => {
      $.ajax({
        type: 'POST',
        url: `${window.globals.versions.api}/auth/is_sign_eula_required/`,
        headers: {
          Authorization: `Armis ${csrf}`,
        },
        data: {
          username: this.props.params.u,
        },
        success: (response) => {
          this.setState({ isSigningEulaRequired: response.data });
        },
        error: function (xhr, errorType) {
          console.error(errorType);
        },
      });
    });
  }
}
