import _ from 'lodash';
import PropTypes from 'prop-types';
import $ from 'zepto';

import RequestWithCSRF from './RequestWithCSRF.js';
import template from './StandardLogin.jsx';
import Common from './common.js';
import consts from './consts.js';

export default class StandardLogin extends RequestWithCSRF {
  constructor(props) {
    super(props);
    this.state = {
      captcha: null,
      captchaResponse: null,
      error: null,
      isSso: true,
      loginSuccessful: false,
      message: null,
      otpMode: false,
      otpToken: '',
      password: '',
      username: '',
      videoReady: false,
    };
    this.render = template.bind(this);
  }

  static get propTypes() {
    return {
      params: PropTypes.object.isRequired,
      autoSsoLogin: PropTypes.bool,
    };
  }
  componentDidMount() {
    if (this.props.autoSsoLogin) {
      this.performIsSsoRequest(null);
    }
    //set timeout to 1 second to allow video to load
    setTimeout(() => this.handleLoadedData(), 1000);
  }

  getRedirectUrl() {
    return this.props.params.r ? this.props.params.r + location.hash : '/';
  }

  onInputChange(key, e) {
    let obj = {};
    obj[key] = e.target.value;
    obj.error = null;
    this.setState(obj);
  }

  isFieldEmpty() {
    return (
      (this.state.otpMode && _.isEmpty(this.state.otpToken)) ||
      (!this.state.otpMode && _.isEmpty(this.state.username))
    );
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.isFieldEmpty()) {
      this.setError(consts.ERROR_MISSING_REQUIRED_FIELD);
      return;
    }
    this.performLogin();
  }

  onNext(e) {
    e.preventDefault();
    if (this.isFieldEmpty()) {
      this.setError(consts.ERROR_MISSING_REQUIRED_FIELD);
      return;
    }
    this.performIsSsoRequest(this.state.username);
  }

  handleIsSsoResult(result) {
    let data = result.data;
    if (data?.ssoEnabled) {
      this.setState({ isSso: true });
      this.post((csrf) => {
        $.ajax({
          type: 'POST',
          timeout: 5000,
          url: `${window.globals.versions.api}/auth/login/sso/`,
          headers: {
            Authorization: `Armis ${csrf}`,
          },
          data: {
            username: this.state.username,
            redirect: this.getRedirectUrl(),
          },
          success: this.handleLoginResult.bind(this),
          error: (xhr, errorType) => {
            if (data.bypassSSO) {
              this.setState({ isSso: false });
            } else {
              this.setError(consts.ERROR_SSO_UNAVAILABLE);
              console.error(errorType);
            }
          },
        });
      });
    } else {
      this.setState({ isSso: false });
    }
  }

  performLogin() {
    this.post((csrf) => {
      $.ajax({
        type: 'POST',
        url: `${window.globals.versions.api}/auth/login/`,
        headers: {
          Authorization: `Armis ${csrf}`,
        },
        data: {
          organization: 'armis',
          username: this.state.username,
          password: this.state.password,
          otpToken: this.state.otpToken,
          otp_token: this.state.otpToken, // eslint-disable-line camelcase
          captchaResponse: this.state.captchaResponse,
          captcha_response: this.state.captchaResponse, // eslint-disable-line camelcase
        },
        success: this.handleLoginResult.bind(this),
        error: function (xhr, errorType) {
          console.error(errorType);
        },
      });
    });
  }

  performIsSsoRequest(username) {
    this.post(() => {
      $.ajax({
        url: `${window.globals.versions.api}/auth/login/is_sso_enabled/`,
        data: {
          username,
        },
        success: this.handleIsSsoResult.bind(this),
        error: function (xhr, errorType) {
          console.error(errorType);
        },
      });
    });
  }

  handleRecaptchaResponse(response) {
    let captchaState = {
      captchaResponse: response,
    };

    if (this.state.error === consts.ERROR_INVALID_CAPTCHA) {
      captchaState.error = '';
    }

    this.setState(captchaState);
  }

  handleLoginResult(result) {
    if (result) {
      if (result.success) {
        this.setState({ loginSuccessful: true });
        if (result.data && result.data.isSso) {
          location.href = result.data.redirect;
        } else {
          localStorage.setItem('csrf', result.csrf);
          let redirect = this.getRedirectUrl();
          // Allow only redirects starting with "?", "#", or a single "/"
          if (/^\/([?#\w]|$)/.test(redirect)) {
            location.href = redirect;
          }
        }
      } else {
        this.setState({
          captchaSiteKey: result.captchaSiteKey,
          otpMode: Common.isOtpMode(result.message),
          error: result.message === consts.ERROR_NO_OTP ? null : result.message,
          message: null,
        });
      }
    } else {
      this.setError(consts.ERROR_OTHER);
    }
  }

  requestResetPassword() {
    if (this.state.username) {
      this.post((csrf) => {
        $.ajax({
          type: 'POST',
          url: `${window.globals.versions.api}/auth/request_reset_password/`,
          headers: { Authorization: `Armis ${csrf}` },
          data: { username: this.state.username },
          success: this.handleRequestResetPasswordResult.bind(this),
          error: function (xhr, errorType) {
            console.error(errorType);
          },
        });
      });
    } else {
      this.setError(consts.ERROR_MISSING_USERNAME);
    }
  }

  handleRequestResetPasswordResult() {
    this.setMessage(consts.LOGIN_RESET_PASSWORD_EMAIL_SENT_MESSAGE);
  }

  setError(error) {
    this.setState({ error, message: null });
  }

  setMessage(message) {
    this.setState({ message, error: null });
  }

  handleLoadedData() {
    this?.setState({ videoReady: true });
  }
}
