import React from 'react';
import $ from 'zepto';

import Popup from './Popup.js';

function getLoginBanner(setText) {
  $.ajax({
    type: 'GET',
    url: '/api/login_banner',
    success: (response) => {
      if (response.success && response.data.enabled) {
        setText(response.data.text);
      }
    },
    error: function (xhr, errorType) {
      console.error(errorType);
    },
  });
}

export default function LoginPopup() {
  const [text, setText] = React.useState(null);
  React.useEffect(() => getLoginBanner(setText), []);
  return <div>{text && <Popup text={text} />}</div>;
}
