export enum LogLevel {
  debug = 1,
  info = 2,
  warning = 3,
  error = 4,
  critical = 5,
}

export enum LogSource {
  browser = 1,
}

export enum Environment {
  local = 1,
  test = 2,
  prod = 3,
}
