export const serializeErrors = (_key: any, value: any): object => {
  if (value instanceof Error) {
    const newValue = Object.getOwnPropertyNames(value).reduce(
      (obj: { [key: string]: any }, propName: string) => {
        obj[propName] = value[propName as keyof Error];
        return obj;
      },
      { name: value.name }
    );
    return newValue;
  } else {
    return value;
  }
};

export const validateLogMessage = (
  msg: string,
  enrichment: object
): boolean => {
  if (
    typeof msg === 'string' &&
    typeof enrichment === 'object' &&
    !Array.isArray(enrichment) &&
    enrichment !== null
  ) {
    return true;
  }

  return false;
};
