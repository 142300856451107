/*eslint no-undef: 0, no-unused-vars: 0*/
import { Environment, LogLevel } from '@armis/observability';

const FETCH_TIMEOUT_MS = 1000;

function fetchTimeout(url) {
  return Promise.race([
    fetch(url),
    new Promise((_, reject) =>
      setTimeout(reject, FETCH_TIMEOUT_MS, new Error('fetch timed out'))
    ),
  ]);
}

function getDefaultGlobalConfig() {
  return {
    logos: {
      centrix: 'centrix',
      console: 'console-navbar-logo',
      consoleHoliday: 'console-navbar-logo-holiday',
      favicon: 'favicon',
      login: 'login-logo',
    },
    links: {
      support: '/api/idp/auth/login/idp_init',
    },
  };
}

function initGlobalConfig(callback) {
  let url = window.globals.versions.globals;
  fetchTimeout(url)
    .then((response) => response.json())
    .then((response) => {
      window.globals.config = response;
    })
    .catch((error) => {
      console.error(
        `Failed fetching from s3.\nFile url: ${url}.\nError: ${error}`
      );
    })
    .finally(() => {
      extendGlobalConfig(callback);
    });
}

function extendGlobalConfig(callback) {
  let url = window.globals.versions.tenantConfigPath;
  fetchTimeout(url)
    .then((response) => response.json())
    .then((response) => {
      window.globals.config = Object.assign(
        {},
        window.globals.config,
        response
      );
    })
    .catch((error) => {
      // We don't care.
    })
    .finally(() => {
      setFavicon();
      callback();
    });
}

function setFavicon(configFavicon = window.globals.config.logos.favicon) {
  let newFaviconHref;

  if (configFavicon instanceof Object) {
    newFaviconHref = configFavicon.src;
  } else {
    let favicon = document.getElementById('favicon');
    let oldFaviconName = favicon.href.split('/').pop();

    let newFaviconName = `${configFavicon}.png`;

    if (oldFaviconName === newFaviconName) {
      return;
    }

    newFaviconHref = favicon.href.replace(oldFaviconName, newFaviconName);
  }

  let newFavicon = document.createElement('link');
  newFavicon.id = 'favicon';
  newFavicon.rel = 'icon';
  newFavicon.href = newFaviconHref;

  document.head.removeChild(favicon);
  document.head.appendChild(newFavicon);
}

function getLogLevel(clientLoggingLogLevel, logEnv) {
  if (logEnv === Environment.local) {
    return LogLevel.debug;
  }

  return clientLoggingLogLevel ? clientLoggingLogLevel : LogLevel.error;
}

export default {
  getDefaultGlobalConfig,
  getLogLevel,
  initGlobalConfig,
  setFavicon,
};
