import * as sourceStackTrace from 'sourcemapped-stacktrace';

import {
  LogMessage,
  LogMessages,
  LoggerConfiguration,
  ObservabilityConfiguration,
} from '../contracts';
import { MessageManagerService } from '../services';
import { LOGGER_DEFAULT_CONFIGURATION } from '../utils/constants';
import { LogLevel, LogSource } from '../utils/enums';
import { validateLogMessage } from '../utils/message-utils';

export class Logger {
  private static instance: Logger;
  private messageManagerService: MessageManagerService<LogMessage>;

  private constructor(
    private configuration: LoggerConfiguration = LOGGER_DEFAULT_CONFIGURATION
  ) {
    this.messageManagerService = new MessageManagerService({
      ...this.configuration,
      messagesParser: Logger.logMessagesParser,
    });
  }

  public static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }

    return Logger.instance;
  }

  public static configure(configuration?: LoggerConfiguration): void {
    const instance = Logger.getInstance();
    instance.configuration = {
      ...LOGGER_DEFAULT_CONFIGURATION,
      ...configuration,
    };

    instance.messageManagerService.configure(
      configuration as ObservabilityConfiguration
    );
  }

  public static getConfiguration(): LoggerConfiguration | undefined {
    return Logger.getInstance().configuration;
  }

  public static getMessageManagerService():
    | MessageManagerService<LogMessage>
    | undefined {
    return Logger.getInstance().messageManagerService;
  }

  private static logMessagesParser(messages: Array<LogMessage>): LogMessages {
    return {
      logs: messages,
    };
  }

  private addLogMessage(
    level: LogLevel,
    msg: string,
    enrichment: object = {}
  ): void {
    const context = this.getLogMessageContext();
    const logMessage: LogMessage = {
      msg,
      level,
      source: LogSource.browser,
      additional_info: enrichment,
      context,
    };

    this.messageManagerService.addMessage(logMessage);
  }

  private handleErrorMessage(level: LogLevel, msg: string, enrichment: Error) {
    sourceStackTrace.mapStackTrace(
      enrichment.stack,
      (stackTrace: Array<string>) => {
        const parsedEnrichment = Object.assign(enrichment, {
          stack: stackTrace.join('\n'),
        });
        this.addLogMessage(level, msg, parsedEnrichment);
      }
    );
  }

  private log(level: LogLevel, msg: string, enrichment: object = {}): void {
    try {
      if (this.configuration?.logLevel && level < this.configuration.logLevel) {
        return;
      }

      if (!validateLogMessage(msg, enrichment)) {
        throw new Error(
          'telemetry message validation failed, please read the docs'
        );
      }

      if (enrichment instanceof Error && enrichment.stack) {
        this.handleErrorMessage(level, msg, enrichment);
      } else {
        this.addLogMessage(level, msg, enrichment);
      }
    } catch (e) {
      console.error('telemetry client sdk logging failed', e);
    }
  }

  private getLogMessageContext(): object {
    const context = {
      location: location?.href,
      userAgent: navigator?.userAgent,
    };

    return context;
  }

  public static critical(msg: string, enrichment: object = {}): void {
    Logger.getInstance().log(LogLevel.critical, msg, enrichment);
  }

  public static error(msg: string, enrichment: object = {}): void {
    Logger.getInstance().log(LogLevel.error, msg, enrichment);
  }

  public static warning(msg: string, enrichment: object = {}): void {
    Logger.getInstance().log(LogLevel.warning, msg, enrichment);
  }

  public static info(msg: string, enrichment: object = {}): void {
    Logger.getInstance().log(LogLevel.info, msg, enrichment);
  }

  public static debug(msg: string, enrichment: object = {}): void {
    Logger.getInstance().log(LogLevel.debug, msg, enrichment);
  }

  public static mapStackTrace(
    stackTrace: string,
    callback: (stackTrace: string) => void
  ) {
    sourceStackTrace.mapStackTrace(stackTrace, (stackArray: Array<string>) => {
      const parsedStackTrace = stackArray.join('\n');
      callback(parsedStackTrace);
    });
  }
}
