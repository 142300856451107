import PropTypes from 'prop-types';
import React from 'react';

import template from './ReCaptcha.jsx';

export default class ReCaptcha extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rendered: false,
    };
    this.render = template.bind(this);
  }

  static get propTypes() {
    return {
      callback: PropTypes.func.isRequired,
      sitekey: PropTypes.string.isRequired,
    };
  }

  renderCaptcha(dom) {
    if (!this.state.rendered && dom) {
      grecaptcha.render(dom, {
        callback: this.props.callback,
        sitekey: this.props.sitekey,
      });
      this.setState({ rendered: true });
    }
  }
}
