import consts from './consts';

function getComponentsConfig(params) {
  if (params.it) {
    params.headerText = consts.CREATE_PASSWORD_TITLE;
    params.submitButtonText = consts.CREATE_PASSWROD_SUBMIT;
  } else {
    params.headerText = consts.RESET_PASSWORD_TITLE;
    params.submitButtonText = consts.RESET_PASSWORD_SUBMIT;
  }
  return params;
}

export { getComponentsConfig };
