import { ObservabilityConfiguration } from '../contracts';
import { Environment, serializeErrors } from '../utils';
import { MessageBufferService } from './message-buffer.service';

export class MessageManagerService<T> {
  readonly buffer: MessageBufferService<T>;
  private timer = 0;
  private configuration: ObservabilityConfiguration;
  private messageCounter: number;

  constructor(observabilityConfiguration: ObservabilityConfiguration) {
    this.listenToVisibilityChange();
    this.configuration = observabilityConfiguration;
    this.buffer = new MessageBufferService();
    this.messageCounter = 0;
  }

  public configure(
    ObservabilityConfiguration: ObservabilityConfiguration
  ): void {
    this.configuration = {
      ...this.configuration,
      ...ObservabilityConfiguration,
    };
  }

  public addMessage(message: T): void {
    if (!this.shouldAddEvent()) {
      return;
    }

    this.messageCounter++;
    this.buffer.addMessage(message);
    this.timer =
      this.timer ||
      window.setTimeout(
        () => this.sendMessage(),
        this.configuration.bufferDelay
      );
  }

  public getConfiguration(): ObservabilityConfiguration {
    return this.configuration;
  }

  public getMessages(): Array<T> {
    return this.buffer.getBuffer();
  }

  public getBuffer(): MessageBufferService<T> {
    return this.buffer;
  }

  private shouldAddEvent(): boolean {
    const { enable, rateLimiter } = this.configuration;

    if (!enable || (rateLimiter && this.messageCounter >= rateLimiter)) {
      return false;
    }

    return true;
  }

  private sendMessage(): void {
    const messages = this.buffer.flushBuffer();

    if (messages?.length ?? 0) {
      const parsedMessages = this.configuration.messagesParser
        ? this.configuration.messagesParser(messages)
        : messages;

      const data = this.stringifyMessages(parsedMessages);
      if (data && this.configuration.telemetryHost) {
        if (this.configuration.env === Environment.local) {
          console.log('logger messages', data);
        } else {
          navigator.sendBeacon(this.configuration.telemetryHost, data);
        }
      }
    }

    clearTimeout(this.timer);
    this.timer = 0;
  }

  private stringifyMessages(data: any): string {
    try {
      return JSON.stringify(data, serializeErrors);
    } catch (e) {
      console.error('telemetry message is corrupted', data);
      return '';
    }
  }

  private listenToVisibilityChange(): void {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this.sendMessage();
      }
    });
  }
}
